
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import UnassinedPackageFilter from '@/components/dropdown/UnassinedPackageFilter.vue';

  export default defineComponent({
    name: 'unassigned-listing',
    components: {
      UnassinedPackageFilter,
      Datatable,
    },
    emit: ['type-selected', 'reset'],
    async setup(props, { emit }) {
      const { t, te, locale } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();
      const shipments = ref([]);

      const selectedCount = computed(() => {
        return shipments.value.length;
      });

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const shareData = () => {
        store.dispatch(Actions.UPDATE_PACKAGES_ARRAY, shipments.value);
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('unassignedListing'), [
          translate('unassigned'),
        ]);
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_UNASSIGNED_PACKAGES_STORE);
      });

      onMounted(async () => {
        loading.value = true;
        await store.dispatch(Actions.GET_UNASSIGNED_PACKAGES);
        loading.value = false;
      });

      const loading = ref(false);
      const tableHeader = ref([
        {
          key: 'checkbox',
          sortable: true,
        },
        {
          name: translate('shipment ID'),
          key: 'shipmentTrackNumber',
          sortable: true,
        },
        {
          name: translate('Package ID'),
          key: 'tracknumber',
          sortable: true,
        },
        {
          name: translate('recipientPhoneNumber'),
          key: 'recipientPhoneNumber',
          sortable: true,
        },
        {
          name: translate('office Name'),
          key: 'officeName',
          sortable: true,
        },
        {
          name: translate('city'),
          key: 'areaName',
          sortable: true,
        },
        {
          name: translate('date'),
          key: 'createdAt',
          sortable: true,
        },
        {
          name: translate('docId'),
          key: 'docId',
          sortable: false,
        },
        {
          name: translate('holderName'),
          key: 'holderName',
          sortable: false,
        },
        {
          name: translate('internalStatus'),
          key: 'internalStatus',
          sortable: false,
        },
        {
          name: translate('externalStatus'),
          key: 'externalStatus',
          sortable: false,
        },

        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const toggleStatusAuction = async (packages) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_UNASSIGNED_PACKAGE, packages);
          await store.dispatch(Actions.GET_UNASSIGNED_PACKAGES);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const moveToShipment = (id) => {
        router.push({ name: 'hub-shipment-editing', params: { id } });
      };

      const editAuction = (id) => {
        router.push({ name: 'unassigned-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_UNASSIGNED_PACKAGE_CURRENT_PAGE,
          val
        );
        await store.dispatch(Actions.GET_UNASSIGNED_PACKAGES);
        loading.value = false;
      };

      const onTypeChange = async (value) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_UNASSIGNED_PACKAGES_FILTER, value);
        await store.dispatch(Actions.GET_UNASSIGNED_PACKAGES);
        loading.value = false;

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        }
      };

      const onReset = async (value) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_UNASSIGNED_PACKAGES_FILTER, value);
        await store.dispatch(Actions.GET_UNASSIGNED_PACKAGES);
        loading.value = false;
      };

      return {
        tableData: computed(() => store.getters.unassignedPackagesList),
        tableHeader,
        toggleStatusAuction,
        translate,
        totalItems: computed(() => store.getters.unassignedPackagesListCount),
        pageChanged,
        goTo,
        shareData,
        selectedCount,
        moveToShipment,
        shipments,
        editAuction,
        loading,
        can,
        locale,
        onTypeChange,
        onReset,
      };
    },
  });
