import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "me-4" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "svg-icon svg-icon-5 svg-icon-gray-500 me-1" }
const _hoisted_7 = {
  class: "badge-light-primary badge me-3",
  style: {"font-size":"13px","padding-top":"14px","padding-bottom":"14px"}
}
const _hoisted_8 = {
  key: 0,
  class: "selected-count mt-2"
}
const _hoisted_9 = { class: "badge badge-primary" }
const _hoisted_10 = { style: {"font-size":"14px"} }
const _hoisted_11 = { class: "card-toolbar" }
const _hoisted_12 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_13 = { class: "svg-icon svg-icon-2" }
const _hoisted_14 = { class: "svg-icon svg-icon-2" }
const _hoisted_15 = { class: "card-body pt-0" }
const _hoisted_16 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_17 = ["value"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "badge-light-info badge" }
const _hoisted_20 = { class: "badge-light-info badge" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_UnassinedPackageFilter = _resolveComponent("UnassinedPackageFilter")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('read', 'unsigned-packages'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                disabled: _ctx.loading,
                type: "button",
                class: "btn btn-flex btn-light btn-active-primary fw-bolder me-3",
                "data-kt-menu-trigger": "click",
                "data-kt-menu-placement": "bottom-start",
                "data-kt-menu-flip": "top-end"
              }, [
                _createElementVNode("span", _hoisted_6, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen031.svg" })
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.translate('filter')), 1)
              ], 8, _hoisted_5),
              _createVNode(_component_UnassinedPackageFilter, {
                onTypeSelected: _ctx.onTypeChange,
                onReset: _ctx.onReset
              }, null, 8, ["onTypeSelected", "onReset"]),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.tableData.length) + " out of " + _toDisplayString(_ctx.totalItems), 1)
            ]),
            (_ctx.shipments.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, [
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.selectedCount) + " " + _toDisplayString(_ctx.selectedCount === 1 ? 'Package' : 'Packages'), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              (_ctx.can('create', 'unsigned-packages'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: "btn btn-primary me-3",
                    to: "shipment-packages",
                    onClick: _ctx.shareData
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_13, [
                        _createVNode(_component_inline_svg, { src: "/media/icons/duotone/Navigation/Plus.svg" })
                      ]),
                      _createTextVNode(" " + _toDisplayString(_ctx.translate('addPackagesToExistingShipment')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              (_ctx.can('create', 'unsigned-packages'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 1,
                    to: "unassigned-creating",
                    class: "btn btn-primary",
                    onClick: _ctx.shareData
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_14, [
                        _createVNode(_component_inline_svg, { src: "/media/icons/duotone/Navigation/Plus.svg" })
                      ]),
                      _createTextVNode(" " + _toDisplayString(_ctx.translate('addNewShipments')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_Datatable, {
            "table-data": _ctx.tableData,
            "table-header": _ctx.tableHeader,
            loading: _ctx.loading,
            "rows-per-page": 100,
            total: _ctx.totalItems,
            "enable-items-per-page-dropdown": false,
            onCurrentChange: _ctx.pageChanged
          }, {
            "cell-checkbox": _withCtx(({ row: shipment }) => [
              _createElementVNode("div", _hoisted_16, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-check-input",
                  type: "checkbox",
                  value: shipment.id,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.shipments) = $event))
                }, null, 8, _hoisted_17), [
                  [_vModelCheckbox, _ctx.shipments]
                ])
              ])
            ]),
            "cell-tracknumber": _withCtx(({ row: packages }) => [
              _createTextVNode(_toDisplayString(packages.trackNumber), 1)
            ]),
            "cell-shipmentTrackNumber": _withCtx(({ row: packages }) => [
              _createElementVNode("a", {
                style: {"cursor":"pointer"},
                class: "text-gray-600 text-hover-primary mb-1",
                onClick: ($event: any) => (_ctx.moveToShipment(packages.originShipmentId))
              }, _toDisplayString(packages.shipmentTrackNumber), 9, _hoisted_18)
            ]),
            "cell-officeName": _withCtx(({ row: packages }) => [
              _createTextVNode(_toDisplayString(packages.officeName), 1)
            ]),
            "cell-createdAt": _withCtx(({ row: packages }) => [
              _createTextVNode(_toDisplayString(packages.createdAt), 1)
            ]),
            "cell-docId": _withCtx(({ row: packages }) => [
              _createTextVNode(_toDisplayString(packages.docId), 1)
            ]),
            "cell-holderName": _withCtx(({ row: packages }) => [
              _createTextVNode(_toDisplayString(packages.holderName), 1)
            ]),
            "cell-areaName": _withCtx(({ row: packages }) => [
              _createTextVNode(_toDisplayString(packages.areaName), 1)
            ]),
            "cell-internalStatus": _withCtx(({ row: packages }) => [
              _createElementVNode("span", _hoisted_19, _toDisplayString(packages.internalStatus), 1)
            ]),
            "cell-externalStatus": _withCtx(({ row: packages }) => [
              _createElementVNode("span", _hoisted_20, _toDisplayString(packages.externalStatus), 1)
            ]),
            "cell-recipientPhoneNumber": _withCtx(({ row: packages }) => [
              _createTextVNode(_toDisplayString(packages.recipientPhoneNumber), 1)
            ]),
            "cell-actions": _withCtx(({ row: packages }) => [
              _createElementVNode("a", {
                href: "#",
                class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1",
                onClick: _withModifiers(($event: any) => (_ctx.moveToShipment(packages.shipmentId)), ["prevent"])
              }, [
                _createElementVNode("span", _hoisted_22, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen010.svg" })
                ])
              ], 8, _hoisted_21)
            ]),
            _: 1
          }, 8, ["table-data", "table-header", "loading", "total", "onCurrentChange"])
        ])
      ]))
    : _createCommentVNode("", true)
}